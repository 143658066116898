import Swiper from "swiper";
// eslint-disable-next-line import/extensions, node/no-missing-import
import { Navigation as swNavigation, Pagination } from "swiper/modules";

window.BASELINES_APP.swipers = window.BASELINES_APP.swipers || [];

const initSwipers = () => {
  const swiperElems = document.querySelectorAll(".swiper");

  for (const swiper of swiperElems) {
    let swiperConfig = {
      loop: true,
      modules: [swNavigation, Pagination], // Install modules
      speed: 400,
      navigation: {
        nextEl: swiper.parentNode.querySelector(".swiper-button-next"),
        prevEl: swiper.parentNode.querySelector(".swiper-button-prev"),
      },
    };

    if (swiper.classList.contains("more-articles-slider")) {
      // Full custom configuration for more-articles-slider with breakpoints
      swiperConfig.slidesPerView = 3.05;
      swiperConfig.spaceBetween = 35;
      swiperConfig.breakpoints = {
        "0": {
          slidesPerView: 1,
          spaceBetween: 15,
        },
        "768": {
          slidesPerView: 2,
          spaceBetween: 25,
        },
        "1024": {
          slidesPerView: 4,
          spaceBetween: 35,
        },
      };
    } else {
      // Full default configuration for other Swipers (without spread operator)
      swiperConfig.breakpoints = {
        "0": {
          slidesPerView: 1,
          spaceBetween: 25,
        },
        "768": {
          slidesPerView: 3,
          spaceBetween: 25,
        },
      };
      swiperConfig.crossfade = true;
    }

    const swiperObj = new Swiper(swiper, swiperConfig);
    window.BASELINES_APP.swipers.push(swiperObj);
  }
};

initSwipers();

module.exports = {
  initSwipers,
};
