import "./_globals.js"; // This code runs before anything else
// import "./_window-scroll.js";
import "./_aos.js";
import "./_window-resize.js";
// Optional: requires register-service-worker@1.7.2
// import "./_register-service-worker.js";
// import "./_navigation.js";
// import "./_skip-link-focus-fix.js";
import "./_swiper-init.js";
import "./_scroll-into-view.js";
import "./_slider.js";
import "./_timeline.js";
import "./_video-plyr.js";
import "./_product-card-deck.js";
import "./_tech-specs.js";
// import "./_dragscroll.js";
import "dragscroll";
