import Swiper from "swiper";
// eslint-disable-next-line import/extensions, node/no-missing-import
import { Navigation as swNavigation, Pagination } from "swiper/modules";

window.BASELINES_APP.swipers = window.BASELINES_APP.swipers || [];

const initSwipers = () => {
  const swiperElems = document.querySelectorAll(".swiper");

  for (const swiper of swiperElems) {
    const swiperObj = new Swiper(swiper, {
      breakpoints: {
        "0": {
          slidesPerView: 1,
          spaceBetween: 25
        },
        "768": {
          slidesPerView: 3,
          spaceBetween: 25
        }
      },
      crossfade: true,
      loop: true,
      modules: [swNavigation, Pagination], // Install modules
      navigation: {
        nextEl: swiper.parentNode.querySelector(".swiper-button-next"),
        prevEl: null
      },

      on: {
        init() {
          const nextBtnElems = this.wrapperEl.querySelectorAll(".swiper-button-next");
          for (const btn of nextBtnElems) {
            btn.addEventListener("click", () => {
              swiperObj.slideNext();
            });
          }
        }
      },
      speed: 400
    });

    // Push swiper onto the global app object
    window.BASELINES_APP.swipers.push(swiperObj);
  }
};

initSwipers();

// document.addEventListener('aos:in', ({ detail }) => {
//   console.log({ msg: 'animated in random item', detail });
// });

// document.addEventListener('aos:in:flexi-post-card-deck--final-slide', ({ detail }) => {
//   console.log({ msg: 'animated in flexi-post-card-deck--final-slide', detail });
// });

module.exports = {
  initSwipers
};
