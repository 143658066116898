import { debounce } from "lodash";

const resizeFunc = () => {
  const isHome = Boolean(document.body.classList.contains("home")); // Almost redundant
  const header = document.querySelector("header");
  const content = document.querySelector(".site-content");
  const fpOverlay = document.querySelector(".fp__overlay");
  const svgMap = document.querySelector(".svg-01.svg");

  if (!(header || content)) {
    return undefined;
  }
};

resizeFunc();

window.addEventListener("resize", debounce(resizeFunc, 100, {
  leading: false,
  trailing: true
}));
