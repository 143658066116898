const timeline_items = document.querySelectorAll(".timeline-item");
const timeline_button = document.querySelector(".timeline-button");
const timeline_container = document.querySelector(".timeline-container");

if (timeline_items.length > 0 && timeline_button && timeline_container) {
  let count = 0;

  document.addEventListener("load", () => {
    timeline_container.style.maxHeight = `${timeline_container.scrollHeight}px`;
  });

  timeline_button.addEventListener("click", () => {
    count++;

    if (count === timeline_items.length - 1) {
      timeline_button.innerHTML = "SHOW LESS";
    }

    if (count === timeline_items.length) {
      count = 0;

      document.querySelector("#timeline")?.scrollIntoView();

      setTimeout(() => {
        timeline_container.style.transition = "max-height 1s";
        timeline_container.style.maxHeight = `${timeline_items[0].offsetHeight}px`;
        timeline_button.innerHTML = "SHOW MORE";

        setTimeout(() => {
          for (const [i, item] of timeline_items.entries()) {
            if (i > 0) {
              item.style.height = 0;
            }
          }

          document.querySelector(".timeline-button")?.scrollIntoView();
          timeline_button.disabled = "";
        }, 950);
      }, 50);

      return;
    }

    timeline_container.style.maxHeight = `${timeline_container.scrollHeight}px`;
    timeline_container.style.transition = "max-height 1s";
    timeline_items[count].style.height = "auto";
    timeline_button.disabled = "true";
    timeline_container.style.maxHeight = `${timeline_container.scrollHeight}px`;

    setTimeout(() => {
      timeline_button.disabled = "";
    }, 1000);
  });

  window.addEventListener("resize", () => {
    timeline_container.style.transition = "none";
    timeline_container.style.maxHeight = `${timeline_container.scrollHeight}px`;
  }, { passive: true });
}
