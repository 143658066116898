/**
 * TECH SPECS TABS
 */

const regionTabs = document.querySelectorAll(".js-regions__item-trigger");

for (const tab of regionTabs) {
  tab.addEventListener("shown.bs.tab", event => {
    const activeTabEl = event.target; // newly activated tab
    const regionId = activeTabEl.dataset.regionNumber;
    const graphicRegions = document.querySelectorAll("[data-lamb-region]");
    // eslint-disable-next-line no-implicit-coercion
    const showAllRegions = !!(activeTabEl.textContent.trim().toLowerCase().includes("carcas"));
    const svgElem = document.querySelector(".c-graphic-lamb");

    for (const region of graphicRegions) {
      region.classList.remove("show");
    }

    if (!regionId) {
      return undefined;
    }

    if (showAllRegions) {
      svgElem.classList.add("show--all-regions");
    } else {
      svgElem.classList.remove("show--all-regions");
    }

    const regionsSelector = showAllRegions ? "[data-lamb-region]" : `[data-lamb-region="${regionId}"]`;
    const selectedRegions = document.querySelectorAll(regionsSelector);
    for (const region of selectedRegions) {
      region.classList.add("show");
    }
  });
}
