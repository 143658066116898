import Plyr from "plyr/dist/plyr.min.js";

const clog = inputs => {
  if (window?.BASELINES_APP.isDevelopment) {
    // eslint-disable-next-line no-console
    console.log(inputs);
  }
};

const options = {
  controls: [
    "play-large",
    "play",
    "progress",
    "current-time",
    "mute",
    "volume",
    // "captions",
    // "settings",
    // "pip",
    // "airplay",
    "fullscreen"
  ],
  tooltips: { controls: false, seek: false },
  volume: 0.45
};

const plyrElems = document.querySelectorAll(".plyr");

window.BASELINES_APP.players = [];

for (const player of plyrElems) {
  window.BASELINES_APP.players.push(new Plyr(player, options));
}

clog(window.BASELINES_APP.players);
